const resx = {
  com: {
    langCn: "中文版",
    langEn: "英文版",
    langDe: "德语",
    home: "首页",
    homecont1_title: "全方位的技术支持",
    homecont1_subtitle: "努力为3D打印机用户提供优秀产品与优质服务",
    homecont1_title1: "主板资料",
    homecont1_subtitle1: "详细介绍乐积3D打印主板的参数设置、操作方法及新增功能",
    homecont1_subtitle1_2:
      "包括系统操作、接口定义、机型设置、电机设置、温度设置等全方面资料",
    newUpdate: "热门",
    learnMore: "查看更多",
    homecont1_title2: "G-code",
    homecont1_subtitle2: "Lerdge G代码用法及参数详解",
    homecont1_subtitle2_1:
      "包括直线运动、弧线运动、软件回抽、自动调平、材料检测等众多G代码详解",
    homepro_title: "靠谱安心的产品选择",
    homepro_subtitle: "乐积3D主控方案，让3D打印更加简单",
    homepro_detail1: "Lerdge-K主板",
    homepro_detail1_1: "√ 支持单进单出、双进单出、双进双出多种打印模式；",
    homepro_detail1_2: "√ 支持Trinamic驱动高级设置和TMC驱动无传感器归位；",
    homepro_detail1_3: "√ 支持六轴驱动；",
    homepro_detail1_4: "√ 支持500℃高温打印；",
    homepro_detail1_5: "√ 更加丰富完备的硬件接口；",
    homepro_detail1_6: "√ 兼具乐积X主板的所有功能。",
    homepro_detail2: "Lergde-X主板",
    homepro_detail2_1: "√ 所有操作均可通过触摸屏完成，用户不需要编写任何程序;",
    homepro_detail2_2:
      "√ 支持笛卡尔、Delta、CoreXY、CoreXZ、Hbot等多种运动结构；",
    homepro_detail2_3: "√ 兼容多种主流切片软件；",
    homepro_detail2_4: "√ Wifi实时控制与文件传输；",
    homepro_detail2_5: "√ 以低花费换取稳定强大的性能。",
    homeMorePro: "更多产品资讯",
    homeload_title: "版本更新及下载",
    homeload_subtitle: "满足广大3D打印爱好者全方位的需求",
    homeDownLoad: "下载",
    homeDownLoad_more: "更多",
    QQGroup: "Lerdge 技术交流群",
    CompanyEmail: "公司邮箱",
    Telephone: "联系电话",
    breadDownload: "下载",
    productDetail: "产品概述",
    LearnMore: "查阅更多资料",
    Download: "下载最新配置",
    MoreProduct: "查看更多产品",
    Independent: "自主研发",
    LgCreat1: "乐积主板",
    LgCreat2: "让3D打印变得更简单",
    About: "关于我们",
    Support: "技术支持",
    ContactUs: "如果您有需求，可以通过各种方式联系我们",
    FacebookNum: "https://www.facebook.com/groups/594873880921802",
    WechatPublic: "微信公众平台：乐积科技",
    WechatAccount: "微信号：",
    Email: "业务邮箱：",
    LerdgeCreat: "联系，合作，成为伙伴",
    ViewMore: "查看全部资料",
    DownloadFile: "附件下载：",
    SearchCenter: "欢迎进入乐积搜索中心，请输入关键词查找相关资料...",
    LergeControl: "Lerdge 控制软件",
    DownRight: "立即下载",
    ErrorHandle: "查看错误处理方法",
    LerdgeX: "LERDGE-X固件",
    LerdgeK: "LERDGE-K固件",
    LastFirmware: "最新固件下载",
    FirmwareUpdate: "固件更新方法",
    MethodOne:
      "从www.lerdge.cn 网址下载固件的升级压缩包，并解压到电脑中你能找到的位置（例如电脑桌面）",
    MethodTwo:
      "在解压后的文件夹中打开Lerdge_K_normal 文件夹。拷贝名为Lerdge_K_system的整个文件夹到存储器中",
    MethodTwoX:
      "在解压后的文件夹中打开Lerdge_X_normal 文件夹。拷贝名为Lerdge_X_system的整个文件夹到存储器中",
    MethodThree1: "将存储器插入主板对应接口，点击",
    MethodThree2: "图标，进入",
    MethodThree3: "系统设置",
    MethodThree4: "界面，点击 ",
    MethodThree5:
      "图标，在对应的存储设备中找到升级文件选中并执行更新。更新完成系统会自动跳转到运用程序。若系统长时间没有跳转，可通过主板上的复位按钮重启主板，或者给主板断电后再上电，重启主板。",
    MethodFour:
      "注意：存储器中存放Lerdge_K_system文件夹的路径中应为全英文路径。否则可能导致升级失败或别的软件问题。",
    MethodFourX:
      "注意：存储器中存放Lerdge_X_system文件夹的路径中应为全英文路径。否则可能导致升级失败或别的软件问题。",
    DownloadCenter: "欢迎您使用乐积在线下载中心",
    DownloadFirmware: "下载固件",
    releaseTime: "发布时间：",
    downloadTimes: "下载次数：",
    ButIt: "立即购买",
    Documentation: "资料查阅",
    DownloadPage: "下载中心",
    ProductPage: "产品中心",
    Tips: "温馨提示：您的问题会发送到",
    ThisMailbox: "邮箱，我们会在工作时间内尽快回复",
    Worktime: "工作时间：",
    MondayToFriday: "周一 ~ 周五 （09:00-18:00）",
    YourEmail: "您的联系邮箱",
    YourProblem: "您遇到的问题",
    DescribeProblem: "请详细描述您遇到的问题",
    VerifyCode: "验证码",
    AddPicture: "添加图片",
    Cancel: "取 消",
    Submit: "提 交",
    ContactTitle: "联系我们",
    AboutProfile1:
      "深圳市前海乐积科技有限公司（简称：乐积科技）创立于2015年，公司注册于深圳前海创意孵化园，公司自成立之日起就致力于3D打印机核心产品的研发和生产，努力为3D打印机用户提供优秀产品与优质服务。",
    AboutProfile2:
      "公司自主研发的3D打印机主板于2016年初推向市场服务用户，该产品自上市以来以稳定的性能和强大的功能获得用户高度认可。公司研发团队以执着、乐观、积极、坚韧的科研精神继续开拓创新，砥砺前行，在不断更新丰富老款主板功能的同时，又研发出以ARM corte-M4为内核的X系列与K系列全彩触摸屏控制3D打印主板...",
    HotLine: "咨询热线：",
    HotLineNum: "13543321692",
    PhoneTip: "(公司固定电话休息日无人接听)",
    Keyword: "关键词",
    ProductDes: "产品描述",
    IsRequired: "必填项不能为空",
    IsEmail: "邮箱格式不正确",
    MessageSubmit: "您的信息已提交，请耐心等待...",
    UploadSize: "上传文件大小不能超过 2MB",
    StartText: "请按住滑块，拖动到最右边",
    YesTest: "验证通过",
    ErrorText1: "哎呀，出错了，点击",
    ErrorText2: "刷新",
    ErrorText3: "再来一次",
    ErrorNetWord1: "网络不给力，请",
    ErrorNetWord2: "点击刷新",
    Language: "English",
    serviceTitle: "增值服务",
    serviceTitleh1: "更加可靠",
    serviceTitleh2: "才能省心",
    serviceTitle1_0: "售后保障",
    serviceTitle1: "售后保障及免责声明",
    serviceTitleS1: "合理保障您的合法权益...",
    serviceCont1_1:
      "1. 主板类产品保修60天，需自行承担寄出运费，乐积承担维修后寄回的运费；",
    serviceCont1_2:
      "2. 人为损坏不在保修范围内，包括接线错误、短路、非法升级、私自焊接拆除元件等造成的故障问题。此类问题需自行承担来回运费以及维修元件费用，乐积仅提供维修服务；",
    serviceCont1_3:
      "3. 主板、触摸屏采用双层EVA+加厚飞机盒保护，确保物流无损；触摸屏为玻璃制品，使用过程请注意保护，损坏无法维修，需重新购买；",
    serviceCont1_4:
      "4. 如提交此订单，则视为同意以上条款，享有产品售后保障、履行免责声明。",
    serviceTitle2: "注意事项",
    serviceTitleS2: "更贴心地为您服务...",
    serviceCont2_1:
      "1. 使用前请先查看官网参数设置教程、接线教程，使用设置文档、注意事项等；",
    serviceCont2_2: "2. 请勿带电对主板进行接线或者更换各类模块等操作；",
    serviceCont2_3:
      "3. 安装电机驱动时，请注意驱动方向是否正确，针脚是否插接正确；",
    serviceCont2_4: "4. 注意电源的正负极接线；",
    serviceCont2_5:
      "5. 接触主板及安装主板时，请注意静电，并确保背面没有接触金属或者导电材质；",
    serviceCont2_6: "6. 请保持主板干燥清洁。",
    serviceTitle3: "申请返修",
    serviceTitleS3: "如果您遇到无法解决的问题，请随时联系我们...",
    serviceCont3_1: "亲爱的Lerdge顾客：",
    serviceCont3_2:
      "很遗憾得到您的主板出现了硬件故障的消息，Lerdge为广大3D打印机用户提供优质的控制主板，同时也提供免费维修检查服务。 请点击“提交申请”以填写维修申请的详细信息：",
    serviceTitle4: "退款细则",
    serviceTitleS4: "保障您的利益...",
    serviceCont4:
      "购买主板如发现不合适，请于7天内联系客服退回，请确保主板无压痕、无划痕、未附着无法清理的异物等，确保触摸屏未撕掉保修膜、无刮痕，不影响二次销售：",
    serviceCont4_1: "①. 产品无任何问题，全额退款；",
    serviceCont4_2: "②. 主板无问题触摸屏有问题，退总额的80%；",
    serviceCont4_3: "③. 主板有问题触摸屏无问题，退总金额的70%；",
    serviceCont4_4: "④. 主板有问题触摸屏有问题，退总额的50%。",
    submitApply: "提交申请",
    ApplyRepair: "申请返修",
    serviceTip: "请详细描述产品需要返修的问题.",
    serviceTip1: "维修费用说明:",
    serviceTip2: "1. 客户将主板寄送到lerdge的运输费用：客户自行承担;",
    serviceTip3: "2. 将主板寄送给客户的运输费用：Lerdge承担;",
    serviceTip4: "3. 除更换主控外，Lerdge维修主板时产生的费用：Lerdge承担;",
    serviceTip5: "4. 若需要更换主控，主控费用：客户自行承担（RMB：46元）.",
    aliexpress: "速卖通",
    taobao: "Lerdge 淘宝店铺",
    CopyrightNotice:
      "转载版权归作者及来源网站所有，本站原创内容转载请注明来源，联系邮箱：support@lerdge.com",
    pleaseEnter: "请输入搜索内容...",
    noContent: "暂无内容...",
    config: "配置",
    Physicaldisplay: "实物展示",
    modelDownload: "模型下载",
    PrinterSettings: "打印机设置",
    Systemset: "系统设置",
    Machineset: "机器设置",
    Motorsettings: "电机设置",
    Tempset: "温度设置",
    Reset: "重置",
    save: "保存",
    DownloadGcode: "下载G代码",
    Xoffset: "X轴相对偏移量",
    Yoffset: "Y轴相对偏移量",
    Structure: "结构设置",
    Refueling: "换料设置",
    Automaticleveling: "自动调平",
    Softwareback: "软件回轴",
    Linearextrusion: "线性挤出",
    Doubleheadsetup: "双头设置",
    Levelingpoints: "调平点数",
    Xprobeoffset: "X轴探针偏移量",
    Yprobeoffset: "Y轴探针偏移量",
    Zprobeoffset: "Z轴探针偏移量",
    Probefunction: "探针功能",
    SelectProbefunction: "请选择探针功能",
    Probeopenangle: "探针打开角度",
    Probecloseangle: "探针关闭角度",
    Proberesetangle: "探针复位角度",
    Probewiringmode: "探针接线角度",
    SelectProbewiringmode: "请选择探针接线模式",
    Probetriggermode: "探针触发模式",
    SelectProbetriggermode: "请选择探针触发模式",
    levelingonly: "仅用于调平",
    levelingAndZhoming: "用于调平与Z轴归位",
    E0linear: "E0线性挤出系数",
    E1linear: "E1线性挤出系数",
    Refuelingtemp: "换料温度",
    Refuelingspeed: "换料速度",
    Refuelinglength: "换料长度",
    Extrudinglength: "换料后挤出长度",
    Lengthreloadingshaft: "换料挤出后回轴长度",
    Returnlength: "回轴长度",
    OverloadLength: "重载长度",
    Executionspeed: "执行速度",
    Zlift: "Z轴抬升量",
    Motionstructure: "运动结构",
    SelectMotionstructure: "请选择运动结构",
    StrokespaceSet: "行程空间设置",
    Xspace: "X轴行程空间",
    Yspace: "Y轴行程空间",
    Zspace: "Z轴行程空间",
    Homeset: "归位位置",
    Xhomeposition: "X轴归位位置",
    Selectxposition: "选择X轴归位位置",
    Selectyposition: "选择Y轴归位位置",
    Selectzposition: "选择Z轴归位位置",
    Xhomeoffset: "X轴归位偏移量",
    Xswitchwiring: "X轴归位开关接线模式",
    Xswitchtrigger: "X轴归位开关触发模式",
    Yhomeposition: "Y轴归位位置",
    Yhomeoffset: "Y轴归位偏移量",
    Yswitchwiring: "Y轴归位开关接线模式",
    Yswitchtrigger: "Y轴归位开关触发模式",
    Zhomeposition: "Z轴归位位置",
    Zhomeoffset: "Z轴归位偏移量",
    Zswitchwiring: "Z轴归位开关接线模式",
    Zswitchtrigger: "Z轴归位开关触发模式",
    timeOut: "暂停",
    Pause: "暂停设置",
    AdvancedFeatures: "高级功能",
    Xpauseposition: "X轴暂停位置",
    Ypauseposition: "Y轴暂停位置",
    EXexpansion: "EX 扩展轴",
    Usedfor: "用于",
    EXindependentlimit: "EX轴独立限位",
    Printmoveacce: "打印移动加速度",
    Noloadacce: "空载移动加速度",
    Extrusionacce: "挤出加速度",
    Ultimateacce: "极限加速度",
    Xmaxacce: "X轴最大加速度",
    Ymaxacce: "Y轴最大加速度",
    Zmaxacce: "Z轴最大加速度",
    Emaxacce: "E轴最大加速度",
    Xrundirect: "X轴电机相对运行方向",
    Clockwise: "顺时针",
    Counterclockwise: "逆时针",
    Yrundirect: "Y轴电机相对运行方向",
    Zrundirect: "Z轴电机相对运行方向",
    E0rundirect: "E0轴电机相对运行方向",
    E1rundirect: "E1轴电机相对运行方向",
    Limitspeed: "极限速度",
    Xmaxspeed: "X轴最大速度",
    Ymaxspeed: "Y轴最大速度",
    Zmaxspeed: "Z轴最大速度",
    Emaxspeed: "E轴最大速度",
    Minprintspeed: "打印最小速度",
    Eminacce: "E轴最小加速度",
    Mutationrate: "突变速度",
    Xchangespeed: "X轴突变速度",
    Ychangespeed: "Y轴突变速度",
    Zchangespeed: "Z轴突变速度",
    Echangespeed: "E轴突变速度",
    Homingspeed: "归位速度",
    Xhomingspeed: "X轴归位速度",
    Yhomingspeed: "Y轴归位速度",
    Zhomingspeed: "Z轴归位速度",
    Positionspeed: "定位速度",
    Xpositionspeed: "X轴定位速度",
    Ypositionspeed: "Y轴定位速度",
    Zpositionspeed: "Z轴定位速度",
    XmotorStep: "X轴电机步进值",
    YmotorStep: "Y轴电机步进值",
    ZmotorStep: "Z轴电机步进值",
    E0motorStep: "E0轴电机步进值",
    E1motorStep: "E1轴电机步进值",
    Tempmethod: "测温方式",
    Mintemp: "最小温度",
    Maxtemp: "最大温度",
    pleaseChoose: "请选择",
    Tempprotect: "温度保护阈值",
    ScalefactorSet: "比例系数设置",
    Integrationset: "积分系数设置",
    Differentialset: "微分系数设置",
    No0hothead: "0号热头设置",
    No1hothead: "1号热头设置",
    hotheadset: "热床设置",
    choiceLang: "选择系统语言",
    Extrigger: "Ex 轴触发模式",
    Exwiring: "Ex 轴接线模式",
    DeltaBasicPara: "基本参数",
    MachineRadius: "机器半径",
    PushrodLength: "推杆长度",
    Printradius: "打印半径",
    Printheight: "打印高度",
    Slideroffset: "滑块偏移量",
    Effectoffset: "效应头偏移量",
    Awiring: "A柱归位开关接线模式",
    Atrigger: "A柱归位开关触发模式",
    Bwiring: "B柱归位开关接线模式",
    Btrigger: "B柱归位开关触发模式",
    Cwiring: "C柱归位开关接线模式",
    Ctrigger: "C柱归位开关触发模式",
    Limitheighterror: "限定高度误差",
    ALimitheighterror: "A柱限位开关高度误差",
    BLimitheighterror: "B柱限位开关高度误差",
    CLimitheighterror: "C柱限位开关高度误差",
    PushrodError: "推杆误差",
    APushrodError: "A柱推杆误差",
    BPushrodError: "B柱推杆误差",
    CPushrodError: "C柱推杆误差",
    Backspeed: "回轴速度",
    backacce: "回轴加速度",
    nominispeed: "空载最小速度",
    warmup: "预热设置",
    Hothead0: "热头0",
    Hothead1: "热头1",
    Hotbed: "热床",
    promptLevelPoint: "调平点数为0~32",
    promptoffset: "探针偏移量为0~180°",
    promptopen: "探针打开角度为0~180°",
    promptclose: "探针关闭角度为0~180°",
    promptreset: "探针复位角度为0~180°",
    sliceDown: "切片工具下载",
    InterfaceColor: "界面颜色",
    SystemLanguage: "系统语言",
    SystemTone: "系统提示音",
    ScreenTimeout: "屏幕超时时长",
    sendYourModel: "向乐积发送您的模型",
    ModelIntroduction: "作品介绍",
    IntroductText: "请简要介绍您的作品名称、功能、设计理念等",
    UploadAttachment: "上传附件",
    SendModelToLerdge: "向Lerdge发送我的模型",
    sorryNoDisplay: "很抱歉，暂时没有实物模型",
    ClickUpload: "点击上传",
    UploadTip:
      "请上传格式为.zip, .ZIP, .RAR, .rar, .7z, .7Z的压缩包，且大小不超过30M.",
    weixin: "weixin",
    weibo: "weibo",
    qq: "qq",
    linkedin: "linkedin",
    facebook: "facebook",
    twitter: "twitter",
    sharedThis: "分享到：",
    wechatCode: "请用微信【扫一扫】扫描二维码",
    modelDetail: "模型介绍",
    cannotBlack: "背景不能为黑色",
    LastLang: "最新语言包下载",
    LangUpdate: "语言包更新方法：",
    LangUpdateMethodOne: "将Lerdge主板固件升级至V4.3.0以上版本；",
    LangUpdateMethodTwo:
      "从www.lerdge.cn网站下载所需的语言包，并解压到电脑中你能找到的位置（例如电脑桌面）；",
    LangUpdateMethodThree:
      "在解压后的文件夹中找到名为Lerdge_UI_V4.3.0的文件，拷贝该文件到存储器中；",
    LangUpdateMethodF1: "将存储器插入主板对应接口，点击",
    LangUpdateMethodF2: "图标，进入",
    LangUpdateMethodF3: "系统设置",
    LangUpdateMethodF4: "界面，点击",
    LangUpdateMethodF5:
      "图标，在对应目录中选中上述文件并执行更新。更新完成系统会自动跳转到运用程序。",
    LangUpdateMethodFix:
      "更新完成后，则可以在 系统设置 界面中切换英文和对应语言。",
    LangUpdatenotice:
      "注意：存储器中存放Lerdge_UI_V4.3.0文件夹的路径中应为全英文路径。否则可能导致升级失败或别的软件问题。",
    langDefault1:
      "为了使全球用户更好地享受3D打印的乐趣，我们邀请了来自不同国家的热心用户对UI语言进行了翻译，以便不熟悉英语的人们能够方便地查看本国语言的UI。感谢所有翻译者的辛苦付出，你们确实都做了一个非常棒的工作！",
    langDefault2:
      "事实上，将UI中的英文词汇翻译为本国语言是一件非常困难的事情。无论是翻译者或是乐积团队，都在翻译过程中反复修改了很多次并付出了很多时间。尽管如此，由于不同语言之间的差异性和微妙性，很难保证所有语言都完美地道。在查看乐积官方教程或是阅读乐积产品说明时，请仍以英文和中文表达为准。",
    langDefault3: "注意：语言包适合于Lerdge K、Lerdge Z 及Lerdge X主板。",
    Preview: "预览",
    GcodePreview: "Gcode预览",
    Sethotbedsize: "设置热床尺寸",
    Xhbsize: "X轴热床尺寸",
    XhbEdgesize: "X轴热床边缘尺寸",
    Yhbsize: "Y轴热床尺寸",
    YhbEdgesize: "Y轴热床边缘尺寸",
    Board: "主板",
    Tutorial: "教程",
    AssemblyVideoTutorial: "组装视频教程",
    ClickDownload: "点击下载",
    AssemblyInstructions: "组装说明",
    noMore: "没有更多了 :( ",
    toBeOpened: "[待开放...]",
    isSuccess: "发送成功",
    stldownloadWarn:
      "请知悉：下载的文件仅限于个人使用，不得直接将其用于或在此基础上修改后用于任何商业目的及环境。",
    Disagree: "不同意",
    AgreeAndDownload: "同意并下载",
    community: "社区",
    useTutorial: "使用教程",
    communityChannelTitle: "通过社交媒体关注乐积",
    communityChannelSubTitle: "与乐积广大用户一起享受3D打印的乐趣",
    communityVideoTitle: "来自乐积频道的精彩视频",
    communityPrintTitle: "乐积用户分享的杰出打印品",
    communityModelTitle: "使用乐积主板改装的3D打印机",
    communityDialogTitle: "如果您使用乐积产品制作了心满意足的作品",
    communityDialogSubTitle: "欢迎您发送给我们，分享在乐积社区页",
    LerdgeZ_Board_title: "Lerdge-Z 主板",
    LerdgeZ_Board_detail_1:
      "√ 支持单进单出、双进单出、三进单出（需要扩展模块）等打印模式；",
    LerdgeZ_Board_detail_2: "√ 支持5轴驱动；",
    LerdgeZ_Board_detail_3: "√ 最高打印温度为280℃；",
    LerdgeZ_Board_detail_4: "√ 支持LED状态指示；",
    LerdgeZ_Board_detail_5: "√ 稳定可靠的打印控制；",
    LerdgeZ_Board_detail_6: "√ 兼具乐积X主板的所有功能。",
    homepro_title_1: "乐积系列主板",
    homepro_title_2: "稳定可靠的3D打印控制方案",
    homepro_detail3: "乐积系列主板对比",
    Version: "版本：",
    updateTime: "更新时间：",
    downloadNow: "立即下载",
    assemblyManual: "组装手册",
    firmwareDownload: "固件下载",
    STL_CAD_Preview: "STL/CAD预览",
    BOM_sheet: "BOM表",
    Certifications: "资质证书",
    worldMapTitle: "来自世界各地的乐积用户",
    worldMapSubTitle: "只为同一个兴趣爱好",
    homeMachineTitle: "乐积3D打印机",
    homeMachineSubTitle: "结构开源 无限玩法",
    number: "用户数量",
    sourceLicense: "该文件使用以下开源协议:",
    lerdgeUsersSharingTip:
      "欢迎加入乐积iX QQ群（735538883）来发现更多有趣的改装",
    designerLab: "设计者：",
  },
  form: {},
};

export default resx;
