import Vue from "vue";
import store from "./store";
import App from "./App.vue";
import router from "./router";
import VueProgressBar from "vue-progressbar";
import "normalize.css";
// import './style/layout.scss'
import "@/assets/styles/layout.scss";
import "@/assets/styles/media.scss";
import VuePaginate from "vue-paginate";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import VueLazyload from "vue-lazyload";
import "element-ui/lib/theme-chalk/index.css";
import element from "../src/element/index";
import sysKeywords from "@/sysKeywords";
//引入echarts
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
import "jquery";

import { ValidationProvider } from "vee-validate";
import { extend } from "vee-validate";

import VueQriously from "vue-qriously";

import VueYoutube from "vue-youtube";

import "animate.css";

import BaiduMap from "vue-baidu-map";
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "VEvYEv5BSwqGY18Fs7srqKVVPiFGd2Qc",
});

Vue.use(VueYoutube);

Vue.config.productionTip = false;

Vue.use(VueQriously);

Vue.component("ValidationProvider", ValidationProvider);

extend("isnumber", (value) => {
  var regx = /^(([1-9]{1}\d*)|(0{1}))(\.\d{0,3})?$/;
  var re = new RegExp(regx);
  if (re.test(value)) {
    return true;
  }
  return false;
});

// 引入elementUI
import { Message, Loading } from "element-ui";
Vue.use(element);

import WebpPlugun from "vue-webp-plugin";
Vue.use(WebpPlugun);

//安装message
Message.install = function(Vue) {
  Vue.prototype.$message = Message;
};
Vue.use(Message);
Vue.use(Loading);

//图片懒加载
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require("@/assets/images/timg.gif"),
  loading: require("@/assets/images/timg.gif"),
  attempt: 1,
  listenEvents: ["scroll"],
});

import {
  getCategoryById,
  getCategoryByIds,
  getCategoryByKey,
  getCategoryByKeys,
  converTo,
  eachDepth,
} from "./utils/categoryHelp";
import lang from "./resx/lang";
Vue.use(Viewer);
Vue.use(VuePaginate);
Vue.config.productionTip = false;
Vue.use(router);
const options = {
  color: "#bffaf3",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};
Vue.use(VueProgressBar, options);

new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    // 首次加载
    this.$router.beforeResolve((to, from, next) => {
      this.init(() => {
        document.title = store.state.data.siteInfo.website_title;
        next();
      });
    });
    // 跳由切换
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      this.$Progress.start();
      next();
    });
    // 跳由切换(完成)
    this.$router.afterEach(() => {
      this.$Progress.finish();
    });
  },
  methods: {
    async init(callback) {
      // 取网站信息
      await this.$store.dispatch("loadSiteInfo");
      // 取分类信息
      await this.$store.dispatch("loadCategories");
      callback();
    },
  },
}).$mount("#app");

// 监听滚动
Vue.directive("scroll", {
  inserted: function(el, binding) {
    let f = function(evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  },
});

// lang
Vue.prototype.$lang = {
  current: lang.getCurrent(),
  com: lang.com,
  form: lang.form,
};

// config
Vue.prototype.$cfg = {
  categoryKeyForDocument: "document",
  categoryKeyForProduct: "product",
  categoryKeyForAbout: "about",
  categoryKeyForDownload: "download",
  searchCategoryKey: ["document", "product", "about"],
  upload: {
    file: `${process.env.VUE_APP_BASE_API}/Upload/uploadFile`,
  },
  pageSize: 20,
  baseCaptchUrl: `${process.env.VUE_APP_BASE_API}/mail/verify`,
  sysKeywords,
};

// tools
Vue.prototype.$tools = {
  category: {
    getById(id, converter) {
      return getCategoryById(store.state.data.categories, id, converter);
    },
    getByIds(ids) {
      return getCategoryByIds(store.state.data.categories, ids);
    },
    getByKey(key, converter) {
      return getCategoryByKey(store.state.data.categories, key, converter);
    },
    getByKeys(keys, converter) {
      return getCategoryByKeys(store.state.data.categories, keys, converter);
    },
    converTo(node, converter) {
      return converTo(node, converter);
    },
    eachDepth(list, func) {
      eachDepth(list, func);
    },
  },
};
