import request from "../utils/request";

/**
 * 通过分类关键字查找分类列表
 * /category/categoryListByKeywords
 */
export function categoryListByKeywords(query) {
  return request({
    url: "/category/categoryListByKeywords",
    method: "get",
    param: query
  });
}

/**
 * 通过分类ID查找分类列表
 * /category/categoryList
 */
export function categoryList(parentId) {
  return request({
    url: "/category/categoryList",
    method: "get",
    param: { category_id: parentId }
  });
}

/**
 * 通过分类关键字查找分类详情
 * /category/categoryDetailByKeywords
 */
export function categoryDetailByKeywords(query) {
  return request({
    url: "/category/categoryDetailByKeywords",
    method: "get",
    param: query
  });
}

/**
 * 通过分类ID查找分类详情
 * /category/categoryDetail
 */
export function categoryDetail(query) {
  return request({
    url: "/category/categoryDetail",
    method: "get",
    param: query
  });
}
