// import store from "@/store"

function getById(list, id, converter) {
  let cur;
  for (var item of list) {
    if (item.category_id == id) {
      cur = item;
      break;
    } else if (item.child.length > 0) {
      cur = getById(item.child, id);
      if (cur) {
        break;
      }
    }
  }

  if (cur && converter) {
    return eachConverTo(cur, converter);
  }
  return cur;
}

function getByIds(list, ids) {
  let result = [];
  ids.forEach((x) => {
    result.push(getById(list, x));
  });
  return result;
}

function getByKey(list, key, converter) {
  let cur;
  for (var item of list) {
    if (item.category_keywords == key) {
      cur = item;
      break;
    } else if (item.child.length > 0) {
      cur = getByKey(item.child, key);
      if (cur) {
        break;
      }
    }
  }

  if (cur && converter) {
    return eachConverTo(cur, converter);
  }
  return cur;
}

function getByKeys(list, keys, converter) {
  let result = [];
  keys.forEach((x) => {
    var cur = getByKey(list, x, converter);
    if (cur) {
      result.push(cur);
    }
  });

  return result;
}

function eachConverTo(node, converter) {
  let result = converter(node);
  result.child = [];
  if (node.child.length > 0) {
    for (var item of node.child) {
      result.child.push(eachConverTo(item, converter));
    }
  }

  return result;
}

let innerEachDepth = (list, func) => {
  for (var item of list) {
    func(item);
    if (item.child && item.child !== null && item.child.length > 0) {
      eachDepth(item.child, func);
    }
  }
};

export function getCategoryById(list, id, converter) {
  return getById(list, id, converter);
}

export function getCategoryByIds(list, id) {
  return getByIds(list, id);
}

export function getCategoryByKey(list, key, converter) {
  var result = getByKey(list, key, converter);
  return result;
}

export function getCategoryByKeys(list, keys, converter) {
  return getByKeys(list, keys, converter);
}

export function converTo(node, converter) {
  return eachConverTo(node, converter);
}

export function eachDepth(list, func) {
  innerEachDepth(list, func);
}
