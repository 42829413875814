/* eslint-disable no-console */
import axios from "axios"
import {  Message } from 'element-ui'
import store from "@/store"

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {

    // do something before request is sent
    store.state.isShowLoader = true
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    store.state.isShowLoader = false

    res.success = (res.code || 0) === 1
    if (!res.success) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      });
    }
    return res
  },
  error => {
    store.state.isShowLoader = false

    console.log("err" + error)
    return Promise.reject(error)
  }
)

export default service
