<template >
  <div id="app">
    <!-- <breadcrumbs></breadcrumbs> -->
    <transition name="fade" mode="in-out">
      <router-view></router-view>
    </transition>
    
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {}
  },
};

</script>


<style>

html,
body,
#app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

[v-cloak] {
          display: none;
        }
        nav.breadcrumbs ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        nav.breadcrumbs ul li {
            display: inline-block;
        }
        nav.breadcrumbs ul li:before {
            content: '/';
            margin-left: 5px;
        }
        nav.breadcrumbs ul li:first-child:before {
            display: none;
        }
</style>
    