const resx = {
    com: {
        langCn:'Chinesisch',
        langEn:'Englisch',
        langDe: 'Deutsche',
        home:'Zuhause',
        homecont1_title:'Umfassender technischer Support',
        homecont1_subtitle:'Bieten Sie herausragende Produkte und exzellenten Service für Benutzer von 3D-Druckern',
        homecont1_title1:'Motherboard-Tutorial',
        homecont1_subtitle1:'Detaillierte Einführung in die Parametereinstellung, die Bedienungsmethode und die neuen Funktionen des Lerdge 3D-Druck-Motherboards',
        homecont1_subtitle1_2:'Einschließlich Systembetrieb, Schnittstellendefinition, Modelleinstellungen, Motoreinstellungen, Temperatureinstellungen usw.',
        newUpdate:'Heißes Update',
        learnMore:'Mehr erfahren',
        homecont1_title2:'G-code',
        homecont1_subtitle2:'Detaillierte Erläuterung der Verwendung und der Parameter des Lerdge G-Codes',
        homecont1_subtitle2_1:'Einschließlich linearer Bewegung, Bogenbewegung, Zurückziehen der Software, automatisches Nivellieren, Materialerkennung usw.',
        homepro_title:'Zuverlässige Produktauswahl',
        homepro_subtitle:'Das Lerdge-Motherboard erleichtert den 3D-Druck',
        homepro_detail1:'Lerdge-K Motherboard',
        homepro_detail1_1:'Unterstützt mehrere Druckmodelle, einschließlich Doppelextrusions-Doppelhitzkopf, Doppelextrusions-Einzelhitzkopf, Einzelextrusions-Einzelhitzkopf;',
        homepro_detail1_2:'Unterstützung der erweiterten Einstellungen des Trinamic-Treibers und des sensorlosen Referenzierens;',
        homepro_detail1_3:'Unterstützung 6-Achs-Treiber;',
        homepro_detail1_4:'Unterstützt 500 ℃ Hochtemperaturdruck;',
        homepro_detail1_5:'Reichlichere Hardware-Schnittstelle;',
        homepro_detail1_6:'Erweitern Sie alle Funktionen des Lerdge-X-Motherboards.',
        homepro_detail2:'Lergde-X Motherboard',
        homepro_detail2_1:'Alle Vorgänge können per Bildschirm ausgeführt werden, ohne dass C ++ - Code debuggt werden muss.',
        homepro_detail2_2:'Unterstützung von Sportstrukturen von Cartesian, Delta, CoreXY, CoreXZ, Hbot;',
        homepro_detail2_3:'Kompatibel mit gängiger Slicing-Software;',
        homepro_detail2_4:'Wifi-Echtzeitsteuerung und Dateiübertragung;',
        homepro_detail2_5:'Niedrigere Kosten, hohe Stabilität und leistungsstarke Leistung.',
        homeMorePro:'Weitere Produktinformationen',
        homeload_title:'Maßgeschneiderte Dienstleistungen',
        homeload_subtitle:'Meet the rich needs of 3D printing enthusiasts',
        homeDownLoad:'Download firmware',
        homeDownLoad_more:'More versions',
        QQGroup:'QQ Group',
        CompanyEmail:'Company email',
        Telephone:'Contact number',
        breadDownload:'Download',
        productDetail:'Description',
        LearnMore: 'Learn more',
        Download: 'Download',
        MoreProduct: 'More product',
        Independent: 'to be modify',
        LgCreat1: 'LERDGE',
        LgCreat2: 'make 3D printing more easy',
        About: 'About Lerdge',
        Support: 'support',
        ContactUs: 'If you have a need, you can contact us in various ways',
        FacebookNum:'https://www.facebook.com/groups/594873880921802',
        WechatPublic: 'WeChat:Lerdge',
        WechatAccount: 'WeChat:',
        Email: 'email:',
        LerdgeCreat: 'Learn more about us...',
        ViewMore: 'View full profile',
        DownloadFile: 'Download:',
        SearchCenter: 'Welcome to the lerdge Search Center, please enter keywords to find related information...',
        LergeControl: 'Lerdge control tool',
        DownRight: 'Download',
        ErrorHandle: 'Upgrade error handling method',
        LerdgeX: 'Lerdge-x Firmware',
        LerdgeK: 'Lerdge-k Firmware',
        LastFirmware: 'Last Firmware',
        FirmwareUpdate: 'Firmware update method',
        MethodOne: 'Download the firmware upgrade package from www.lerdge.cn and unzip it to a location you can find on your computer (such as your desktop)',
        MethodTwo: 'Open the Lerdge_K_normal folder in the unzipped folder. Copy the entire folder named Lerdge_K_system to the storage',
        MethodTwoX: 'Open the Lerdge_X_normal folder in the unzipped folder. Copy the entire folder named Lerdge_X_system to the storage',
        MethodThree1: 'Insert the memory into the corresponding interface of the motherboard, click',
        MethodThree2: 'the icon to enter',
        MethodThree3: 'the system setting',
        MethodThree4: 'interface, click',
        MethodThree5: 'the icon, select the item and perform the update. After the update is complete, the system will automatically jump to the application. If the system does not jump for a long time, you can restart the motherboard by using the reset button on the motherboard, or power off and restart the motherboard.',
        MethodFour: 'Note: The path to the “Lerdge_K_system” folder in the storage should be a full English path. Failure to do so may cause upgrade failures or other software problems.',
        MethodFourX: 'Note: The path to the “Lerdge_X_system” folder in the storage should be a full English path. Failure to do so may cause upgrade failures or other software problems.',
        DownloadCenter: 'Welcome to the online download center',
        DownloadFirmware: 'Download',
        releaseTime: 'release time:',
        downloadTimes: 'Download Times:',
        ButIt: 'Buy It',
        Documentation: 'Documentation',
        DownloadPage: 'Download',
        ProductPage: 'product',
        Tips: 'Tips: Your question will be sent to',
        ThisMailbox: 'The letter in this mailbox, we will reply as soon as possible during working hours',
        Worktime: 'worktime:',
        MondayToFriday: 'Monday to Friday (09: 00-18: 00)',
        YourEmail: 'Your email:',
        YourProblem: 'Your problem',
        DescribeProblem: 'Please describe in detail your problem',
        VerifyCode: 'Verification code',
        AddPicture:'Add picture',
        Cancel: 'cancel',
        Submit: 'submit',
        ContactTitle: 'Contact us',
        AboutProfile1: 'Shenzhen Qianhai Lerdge Technology Co., Ltd. (abbreviated as Lerdge Technology) was established in 2015. The company was registered in Shenzhen Qianhai Creative Incubation Park. Since its establishment, the company has been committed to the research and development and production of 3D printer core products. ',
        AboutProfile2:'To provide 3D printer users with excellent products and quality services. The company has self-developed 3D printer motherboard was launched in the market to serve users in early 2016. Since its launch, this product has been highly recognized by users for its stable performance and powerful functions. The company has research and development team continues to pioneer and innovate with a persistent, optimistic, positive, and tenacious scientific research spirit. While continuously updating and enriching the functions of the old motherboards, it has also developed X-series and K-series full-scale ARM corte-M4 cores...',
        HotLine:'Support Hotline:',
        HotLineNum:'********',
        PhoneTip:'(The company is fixed phone number is not answered on rest days)',
        Keyword:'keyword',
        ProductDes:'Product Description',
        IsRequired:'Required fields cannot be empty.',
        IsEmail:'E-mail format is incorrect.',
        MessageSubmit:'Your information has been submitted, please be patient ...',
        UploadSize:'Upload file size cannot exceed 2MB',
        StartText:'Please slider it',
        YesTest:'Verified',
        ErrorText1:'Oops, something wrong, click',
        ErrorText2:'refresh ',
        ErrorText3:'again',
        ErrorNetWord1:'The network is weak, please',
        ErrorNetWord2:'click refresh',
        Language:'切换中文',
        serviceTitle:'Value-added services',
        serviceTitleh1:'To be more reliable',
        serviceTitle1_0:'Protection & Disclaimer',
        serviceTitle1:'Protection and Disclaimer',
        serviceTitleS1:'Reasonably protect your legal rights...',
        serviceCont1_1:'1. Motherboard maintenance is guaranteed for 30 days. The single-trip shipping cost shall be borne by yourself, and Lerdge will bear the shipping costs after repair.',
        serviceCont1_2:'2. Artificial damage is not covered by the warranty, including faults caused by wiring errors, short circuits, illegal upgrades, and unauthorized soldering of components. Customers with such problems are responsible for round-trip shipping costs and repair component costs. Lerdge only provides repair services.',
        serviceCont1_3:'3. Motherboard and touch screen are protected by double-layer EVA+ thickened box to ensure non-destructive logistics. The touch screen is made of glass. Please protect the screen during use because the screen cannot be repaired if it was damaged and need to be purchased again.',
        serviceCont1_4:'4. If you submit this order, you will be deemed to agree to the above terms, enjoy product after-sale protection, and perform the disclaimer.',
        serviceTitle2:'Precautions For Use',
        serviceTitleS2:'Serve you more intimately...',
        serviceCont2_1:'1. Please refer to parameter setting tutorial, wiring tutorial, use setting documents and precautions, etc in our official website before use.',
        serviceCont2_2:'2. Do not wire or replace various module when your motherboard is powered.',
        serviceCont2_3:'3. When installing the motor drive, please pay more attention to whether the driving direction is correct and whether the pins are properly inserted.',
        serviceCont2_4:'4. Pay attention to the positive and negative terminals of the wiring.',
        serviceCont2_5:'5. When touching and installing the motherboard, please pay attention to static electricity, and ensure that the back of the motherboard does not touch metal or conductive material.',
        serviceCont2_6:'6. Please keep the motherboard dry and clean.',
        serviceTitle3:'Apply for repair',
        serviceTitleS3:'If you encounter an unsolvable problem, please feel free to contact us...',
        serviceTitle4:'Refund rules',
        serviceTitleS4:'Protect your interests...',
        serviceCont4:'If the motherboard is not suitable for purchase, please contact customer representative within 7 days to return it. Please ensure that the motherboard is free of indentations and scratches, no foreign matters that cannot be cleaned is attached, and ensure that the touch screen has not been teared off the film, no scratches, and does not affect the secondary sales:',
        serviceCont4_1:'①. If the products have no any problem, 100% of total amount will be refunded;',
        serviceCont4_2:'②. If the motherboard has no any problem but the screen has some problem, 80% of total amount will be refund;',
        serviceCont4_3:'③. If the screen has no any problem but the motherboard has some problem, 70% of total amount will be refunded;',
        serviceCont4_4:'④. If both motherboard and screen have some problem, 50% of total amount will be refunded.',
        submitApply:'Submit Application',
        serviceCont3_1:'Dear Lerdge customers:',
        serviceCont3_2:'I am sorry to get the message that there is a hardware failure on your motherboard, Lerdge provides high-quality control motherboards for the majority of 3D printer users, and also provides free maintenance and inspection services. Please click "Submit Application" to fill in the detailed information for repair application:',
        ApplyRepair:'Apply for Repair',
        serviceTip:'Please describe in detail the problem that the product needs to be repaired.',
        serviceTip1:'Repair cost details:',
        serviceTip2:'1. The shipping cost of the customer sending the motherboard to lerdge: the customer bears the shipping cost.',
        serviceTip3:'2. Shipping costs for sending the motherboard to customers: Lerdge bears.',
        serviceTip4:'3. When it is not necessary to replace the main control chip, Lerdge bears the cost of repairing the motherboard.',
        serviceTip5:'4. If the main control chip needs to be replaced, the cost of the main control chip is borne by the customer (USD: 7 $).',
        aliexpress:'Aliexpress',
        taobao:'Lerdge Taobao shop',
        CopyrightNotice:'The copyright of the reprint belongs to the author and the source website. Please indicate the source of the original content of this site. Contact email: support@lerdge.com',
        pleaseEnter:'Please enter...',
        noContent:'No content yet...',
        config: 'config',
        Physicaldisplay: 'Physical display',
        modelDownload: 'Model Download',
        PrinterSettings: 'Printer Settings',
        Machineset: 'Machine setup',
        Motorsettings: 'Motor settings',
        Tempset: 'Temperature setting',
        Reset: 'Reset',
        save: 'Save',
        DownloadGcode: 'Download G code',
        Xoffset: 'Relative offset of X axis',
        Yoffset: 'Relative offset of Y axis',
        Structure: 'Structure',
        Refueling: 'Refueling',
        Automaticleveling: 'Automatic leveling',
        Softwareback: 'Software back axis',
        Linearextrusion: 'Linear extrusion',
        Doubleheadsetup: 'Double head setup',
        Levelingpoints: 'Leveling points',
        Xprobeoffset: 'X axis probe offset',
        Yprobeoffset: 'Y axis probe offset',
        Zprobeoffset: 'Z axis probe offset',
        Probefunction: 'Probe function',
        SelectProbefunction: 'Please select the probe function',
        Probeopenangle: 'Probe opening angle',
        Probecloseangle: 'Probe closing angle',
        Probewiringmode: 'Probe wiring mode',
        SelectProbewiringmode: 'Please select the probe wiring mode',
        Probetriggermode: 'Probe trigger mode',
        SelectProbetriggermode: 'Please select the probe trigger mode',
        levelingonly: 'For leveling only',
        levelingAndZhoming: 'Used for leveling and Z-axis homing',
        E0linear: 'E0 linear extrusion coefficient',
        E1linear: 'E1 linear extrusion coefficient',
        Refuelingtemp: 'Refueling temperature',
        Refuelingspeed: 'Refueling speed',
        Refuelinglength: 'Refueling length',
        Extrudinglength: 'Extruding length',
        Lengthreloadingshaft: 'Length of reloading shaft',
        Returnlength: 'Return axis length',
        Heavyloadspeed: 'Heavy load speed',
        Executionspeed: 'Execution speed',
        Zlift: 'Z axis lift',
        Motionstructure: 'Motion structure',
        SelectMotionstructure: 'Please select a sports structure',
        StrokespaceSet: 'Stroke space setting',
        Xspace: 'X axis travel space',
        Yspace: 'Y axis travel space',
        Zspace: 'Z axis travel space',
        Homeset: 'Home setting',
        Xhomeposition: 'X axis home position',
        Xhomeoffset: 'X axis homing offset',
        Xswitchwiring: 'X-axis home switch wiring mode',
        Xswitchtrigger: 'X-axis home switch trigger mode',
        Yhomeposition: 'Y axis home position',
        Yhomeoffset: 'Y axis homing offset',
        Yswitchwiring: 'Y-axis home switch wiring mode',
        Yswitchtrigger: 'Y-axis home switch trigger mode',
        Zhomeposition: 'Z axis homing offset',
        Zhomeoffset: 'Z axis homing offset',
        Zswitchwiring: 'Z-axis home switch wiring mode',
        Zswitchtrigger: 'Z-axis home switch trigger mode',
        timeOut: 'Time out',
        Xpauseposition: 'X axis pause position',
        Ypauseposition: 'Y axis pause position',
        EXexpansion: 'EX expansion axis',
        Usedfor: 'Used for',
        EXindependentlimit: 'EX axis independent limit',
        Printmoveacce: 'Printing movement acceleration',
        Noloadacce: 'No-load acceleration',
        Extrusionacce: 'Extrusion acceleration',
        Ultimateacce: 'Ultimate acceleration',
        Xmaxacce: 'X axis maximum acceleration',
        Ymaxacce: 'Y axis maximum acceleration',
        Zmaxacce: 'Z axis maximum acceleration',
        Emaxacce: 'E axis maximum acceleration',
        Xrundirect: 'X axis motor relative running direction',
        Clockwise: 'Clockwise',
        Counterclockwise: 'Counterclockwise',
        Yrundirect: 'Y axis motor relative running direction',
        Zrundirect: 'Z axis motor relative running direction',
        E0rundirect: 'E0 axis motor relative running direction',
        E1rundirect: 'E1 axis motor relative running direction',
        Limitspeed: 'Limit speed',
        Xmaxspeed: 'X axis maximum speed',
        Ymaxspeed: 'Y axis maximum speed',
        Zmaxspeed: 'Z axis maximum speed',
        Emaxspeed: 'E axis maximum speed',
        Minprintspeed: 'Minimum printing speed',
        Eminacce: 'E axis minimum acceleration',
        Mutationrate: 'Mutation rate',
        Xchangespeed: 'X-axis sudden change speed',
        Ychangespeed: 'Y-axis sudden change speed',
        Zchangespeed: 'Z-axis sudden change speed',
        Echangespeed: 'E-axis sudden change speed',
        Homingspeed: 'Homing speed',
        Xhomingspeed: 'X axis homing speed',
        Yhomingspeed: 'Y axis homing speed',
        Zhomingspeed: 'Z axis homing speed',
        Positionspeed: 'Positioning speed',
        Xpositionspeed: 'X axis positioning speed',
        Ypositionspeed: 'Y axis positioning speed',
        Zpositionspeed: 'Z axis positioning speed',
        XmotorStep: 'X axis motor step value',
        YmotorStep: 'Y axis motor step value',
        ZmotorStep: 'Z axis motor step value',
        E0motorStep: 'E0 axis motor step value',
        E1motorStep: 'E1 axis motor step value',
        Tempmethod: 'Temperature measurement method',
        Mintemp: 'Minimum temperature',
        Maxtemp: 'Maximum temperature',
        pleaseChoose: 'please choose',
        Tempprotect: 'Temperature protection threshold',
        ScalefactorSet: 'Scale factor setting',
        Integrationset: 'Integration coefficient setting',
        Differentialset: 'Differential coefficient setting',
        No0hothead: 'No. 0 hot head setting',
        No1hothead: 'No. 1 hot head setting',
        hotheadset: 'Hot bed settings',
        weixin: 'weixin',
        weibo: 'weibo',
        qq: 'qq',
        linkedin: 'linkedin',
        facebook: 'facebook',
        twitter: 'twitter'
    },
    form: {}
}

export default resx