import cn from "./cn"
import en from "./en"
import de from "./de"

var lang = process.env.VUE_APP_LANG || 'cn';
var dic = {
    cn: cn,
    en: en,
    de: de
}

function getResx(g, key){
    return dic[lang][g][key] ||
        'undefined';
}

export default {
    getCurrent(){
        return lang;
    },
    com(key){
        return getResx('com', key);
    },
    form(key){
        return getResx('form', key);
    }
}