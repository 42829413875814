// 导入自己需要的组件
import {
    Card,
    Scrollbar,
    Container,
    Upload,
    Tabs,
    TabPane,
    Breadcrumb,
    Aside,
    Main,
    BreadcrumbItem,
    Dropdown,
    Carousel,
    CarouselItem,
    Form,
    FormItem,
    Input,
    Button,
    Tree,
    Dialog,
    Row,
    Col,
    Footer,
    Menu,
    MenuItem,
    MenuItemGroup,
    Submenu,
    Progress,
    Select,
    Option,
    Autocomplete,
    DropdownMenu,
    DropdownItem,
    Badge,
    Tooltip,
    Collapse,
    CollapseItem,
    Switch,
    Checkbox,
    Table,
    TableColumn,
    Steps,
    Step,
    Popover,
    RadioGroup,
    RadioButton
} from 'element-ui'
const element = {
    install: function (Vue) {
        Vue.use(RadioGroup)
        Vue.use(RadioButton)
        Vue.use(Card)
        Vue.use(Scrollbar)
        Vue.use(Steps)
        Vue.use(Step)
        Vue.use(Container)
        Vue.use(Badge)
        Vue.use(Tooltip)
        Vue.use(Table)
        Vue.use(TableColumn)
        Vue.use(Checkbox)
        Vue.use(Switch)
        Vue.use(Collapse)
        Vue.use(CollapseItem)
        Vue.use(Autocomplete)
        Vue.use(Aside)
        Vue.use(Main)
        Vue.use(Upload)
        Vue.use(Button)
        Vue.use(Row)
        Vue.use(Col)
        Vue.use(TabPane)
        Vue.use(Tabs)
        Vue.use(Menu)
        Vue.use(MenuItem)
        Vue.use(MenuItemGroup)
        Vue.use(Submenu)
        Vue.use(Breadcrumb)
        Vue.use(BreadcrumbItem)
        Vue.use(Dropdown)
        Vue.use(DropdownMenu)
        Vue.use(DropdownItem)
        Vue.use(Carousel)
        Vue.use(CarouselItem)
        Vue.use(Form)
        Vue.use(FormItem)
        Vue.use(Input)
        Vue.use(Tree)
        Vue.use(Dialog)
        Vue.use(Footer)
        Vue.use(Progress)
        Vue.use(Select)
        Vue.use(Option)
        Vue.use(Popover)
    }
}
export default element