import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      alias: "/views/home",
      component: (resolve) => require(["@//views/home/index"], resolve),
    },
    {
      path: "/about/:cKeywords",
      component: (resolve) => require(["@//views/about/index"], resolve),
    },
    {
      path: "/document/:cKeywords",
      component: (resolve) => require(["@//views/document/index"], resolve),
    },
    {
      path: "/document/detail/:docKeywords",
      component: (resolve) => require(["@//views/document/index"], resolve),
    },
    {
      path: "/search",
      component: (resolve) => require(["@//views/search/index"], resolve),
    },
    {
      path: "/search/:keyword",
      component: (resolve) => require(["@//views/search/index"], resolve),
    },
    {
      path: "/download",
      component: (resolve) => require(["@//views/download/index"], resolve),
    },
    {
      path: "/download/list/:cKeywords",
      component: (resolve) => require(["@//views/download/list"], resolve),
    },
    {
      path: "/product/detail/:keywords",
      component: (resolve) => require(["@//views/product/detail"], resolve),
    },
    {
      path: "/product/:cKeywords",
      component: (resolve) => require(["@//views/product/index"], resolve),
    },
    {
      path: "/config",
      component: (resolve) => require(["@//views/config/index"], resolve),
    },
    {
      path: "/model/:cKeywords",
      component: (resolve) => require(["@//views/model/index"], resolve),
    },
    {
      path: "/model/detail/:keywords",
      component: (resolve) => require(["@//views/model/detail"], resolve),
    },
    {
      path: "/machine/:cKeywords",
      component: (resolve) => require(["@//views/machine/index"], resolve),
    },
    {
      path: "/machine/:keywordPath/manual",
      component: (resolve) =>
        require(["@//views/machine/manual/index"], resolve),
    },
    {
      path: "/machine/:keywordPath/rtpmanual",
      component: (resolve) =>
        require(["@//views/machine/RTPmanual/index"], resolve),
    },
    {
      path: "/machine/:keywordPath/use",
      component: (resolve) => require(["@//views/machine/use/index"], resolve),
      children: [
        {
          path: ":keyword",
          component: () => import("@/views/machine/use/content"),
        },
      ],
    },
    {
      path: "/machine/:keywordPath/firmware",
      component: (resolve) =>
        require(["@//views/machine/firmware/index"], resolve),
    },
    {
      path: "/machine/:keywordPath/viewstl",
      component: (resolve) =>
        require(["@//views/machine/viewstl/index"], resolve),
    },
    {
      path: "/machine/:keywordPath/viewstl/:str",
      component: (resolve) =>
        require(["@//views/machine/viewstl/index"], resolve),
    },
    {
      path: "/machine/viewstl-detail/:keywords",
      component: (resolve) =>
        require(["@//views/machine/viewstl/stl-detail"], resolve),
    },
    {
      path: "/machine/:keywordPath/viewstl/detail/:keywords",
      component: (resolve) =>
        require(["@//views/machine/viewstl/detail"], resolve),
    },
    {
      path: "/community",
      component: (resolve) => require(["@//views/community/index"], resolve),
    },
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});
export default router;
