import request from '../utils/request';

/**
 * 获取首页设置
 * /Setting/getHomePage
 */
export function getHomePage(query) {
    return request({
        url: '/Setting/getHomePage',
        method: 'get',
        param: query
    })
}