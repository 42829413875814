import Vue from 'vue'
import Vuex from 'vuex'
import { categoryList } from '@/api/category'
import { getHomePage } from '@/api/setting'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        ui: {
            patchHeight: 0
        },
        data: {
            categories: null,
            siteInfo: null
        },
        isShowLoader: false,
        share: {
            cn: ['weixin', 'weibo', 'qq', 'linkedin', 'email', 'douban'],
            en: ['facebook', 'twitter', 'linkedin', 'email', 'weixin']
        }
    },
    mutations: {
        setPatchHeight(state, patchHeight) {
            state.ui.patchHeight = patchHeight;
        },
        setCategories(state, list) {
            state.data.categories = list;
        },
        setSiteInfo(state, data) {
            state.data.siteInfo = data;
        }
    },
    actions: {
        resetHeight({ commit }, bodyHeight) {
            let hh = 80;
            let fh = 20;
            commit('setPatchHeight', bodyHeight - hh - fh);
        },
        loadCategories({ commit, state }) {
            return new Promise((resolve) => {
                if(state.data.categories === null){
                    categoryList(0).then(resp => {
                        if (resp.success) {
                            commit('setCategories', resp.data.list);
                            resolve(resp.data.list);
                        }
                    });
                } else {
                    resolve(state.data.categories)
                }
            })
        },
        loadSiteInfo({ commit, state }) {
            return new Promise((resolve) => {
                if(state.data.siteInfo === null){
                    getHomePage(0).then(resp => {
                        if (resp.success) {
                            commit('setSiteInfo', resp.data);
                            resolve(resp.data);
                        }
                    });
                } else{
                    resolve(state.data.siteInfo)
                }
            })
        }
    },
    getters: {}
})